/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"

import AOS from "aos"
import "aos/dist/aos.css"

// import { withNamespaces } from "react-i18next"

import "typeface-poppins"
import "typeface-roboto"

import "./reset.css"
import "./layout.css"

import { injectIntl } from "react-intl"
import Footer from "./footer"

const Layout = ({ children }) => {
  useEffect(() => {
    AOS.init()
    document.body.classList.add("fade")
    window.addEventListener("load", AOS.refresh)
  })

  return (
    <>
      <div className="container">
        <>{children}</>
      </div>
      <Footer></Footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default injectIntl(Layout)
