import React, { Component } from "react"

import logo from "../images/caribel-logo.svg"
import styles from "./footer.module.css"

export default class Footer extends Component {
  render() {
    return (
      <footer className={styles.footer}>
        <img className={styles.logo} src={logo} />
        <div>
          <a href="/privacy.pdf" target="_blank">
            Privacy Statement
          </a>
        </div>
      </footer>
    )
  }
}
